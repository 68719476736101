export enum SubscriptionLanguageKey {
  Subscription = 'subscription',
}

export enum OnboardingLanguageKey {
  WillAutomaticallyTranslate = 'willAutomaticallyTranslate',
  For = 'for',
  While = 'while',
  Crucial = 'crucial',
  Successfully = 'successfully',
  VisitAWebsite = 'visitAWebsite',
  CertainWords = 'certainWords',
  Page = 'page',
  Learn = 'learn',
  YouCanLearn = 'youCanLearn',
  Language = 'language',
  Context = 'context',
  Comfortable = 'comfortable',
  ItsSimple = 'itsSimple',
  And = 'and',
  BrowseAsYouNormallyDo = 'browseAsYouNormallyDo',
  ToBegin = 'toBegin',
  HoverOverAnyTranslations = 'hoverOverAnyTranslations',
  ToHearPronunciations = 'toHearPronunciations',
  Later = 'later',
  ViewYourProgress = 'viewYourProgress',
  Even = 'even',
  CheckYourWordFluency = 'checkYourWordFluency',
  With = 'with',
  Inline = 'inline',
  Quizzes = 'quizzes',
  NeedABreak = 'needABreak',
  Pause = 'pause',
  From = 'from',
  Browser = 'browser',
  Or = 'or',
}

export enum HomeLanguageKey {
  Language = 'language',
  Words = 'words',
  Easy = 'easy',
  Games = 'games',
  Progress = 'progress',
  Geniuses = 'geniuses',
  English = 'english',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Japanese = 'japanese',
  Korean = 'korean',
  Portuguese = 'portuguese',
  Spanish = 'spanish',
  Chinese = 'chinese',
  Arabic = 'arabic',
  Hebrew = 'hebrew',
  Hindi = 'hindi',
}

export enum RedeemLanguageKey {
  Key = 'key',
  New = 'new',
  Anything = 'anything',
}

export enum MiniGamesLanguageKey {
  Interesting = 'interesting',
  Artist = 'artist',
  VeryGood = 'very good',
}
