import { LanguageId } from '~/networking/schema';

import {
  SubscriptionLanguageKey,
  OnboardingLanguageKey,
  HomeLanguageKey,
  RedeemLanguageKey,
  MiniGamesLanguageKey,
} from './types';

export type LanguageKey =
  | SubscriptionLanguageKey
  | OnboardingLanguageKey
  | HomeLanguageKey
  | RedeemLanguageKey
  | MiniGamesLanguageKey;

// NOTE: In the case where a translation is not available, use an empty string as the value
export const dictionary: Record<LanguageId, Record<LanguageKey, string>> = {
  [LanguageId.En]: {
    [SubscriptionLanguageKey.Subscription]: 'subscription',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'will automatically translate',
    [OnboardingLanguageKey.For]: 'for',
    [OnboardingLanguageKey.While]: 'while',
    [OnboardingLanguageKey.Crucial]: 'crucial',
    [OnboardingLanguageKey.Successfully]: 'successfully',
    [OnboardingLanguageKey.VisitAWebsite]: 'visit a website',
    [OnboardingLanguageKey.CertainWords]: 'certain words',
    [OnboardingLanguageKey.Page]: 'page',
    [OnboardingLanguageKey.Learn]: 'learn',
    [OnboardingLanguageKey.YouCanLearn]: 'you can learn',
    [OnboardingLanguageKey.Language]: 'language',
    [OnboardingLanguageKey.Context]: 'context',
    [OnboardingLanguageKey.Comfortable]: 'comfortable',
    [OnboardingLanguageKey.ItsSimple]: "It's simple",
    [OnboardingLanguageKey.And]: 'and',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'browse as you normally do',
    [OnboardingLanguageKey.ToBegin]: 'to begin',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'hover over any translations',
    [OnboardingLanguageKey.ToHearPronunciations]: 'to hear pronunciations',
    [OnboardingLanguageKey.Later]: 'later',
    [OnboardingLanguageKey.ViewYourProgress]: 'view your progress',
    [OnboardingLanguageKey.Even]: 'even',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'check your word fluency',
    [OnboardingLanguageKey.With]: 'with',
    [OnboardingLanguageKey.Inline]: 'inline',
    [OnboardingLanguageKey.Quizzes]: 'quizzes',
    [OnboardingLanguageKey.NeedABreak]: 'Need a break?',
    [OnboardingLanguageKey.Pause]: 'Pause',
    [OnboardingLanguageKey.From]: 'from',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'or',
    [HomeLanguageKey.English]: 'English',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'language',
    [HomeLanguageKey.Easy]: 'easy',
    [HomeLanguageKey.Words]: 'words',
    [HomeLanguageKey.Games]: 'games',
    [HomeLanguageKey.Progress]: 'progress',
    [HomeLanguageKey.Geniuses]: 'geniuses',
    [RedeemLanguageKey.Key]: 'key',
    [RedeemLanguageKey.New]: 'new',
    [RedeemLanguageKey.Anything]: 'anything',
    [MiniGamesLanguageKey.Interesting]: 'Interesting',
    [MiniGamesLanguageKey.Artist]: 'artist',
    [MiniGamesLanguageKey.VeryGood]: 'Very good!', // NOTE: not used for wikipedia banner
  },
  [LanguageId.Es]: {
    [SubscriptionLanguageKey.Subscription]: 'suscripción',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'automáticamente traducirá',
    [OnboardingLanguageKey.For]: 'por',
    [OnboardingLanguageKey.While]: 'mientras',
    [OnboardingLanguageKey.Crucial]: 'crucial',
    [OnboardingLanguageKey.Successfully]: 'exitosamente',
    [OnboardingLanguageKey.VisitAWebsite]: 'visitas un sitio en la red',
    [OnboardingLanguageKey.CertainWords]: 'ciertas palabras',
    [OnboardingLanguageKey.Page]: 'página',
    [OnboardingLanguageKey.Learn]: 'aprender',
    [OnboardingLanguageKey.YouCanLearn]: 'puedes aprender',
    [OnboardingLanguageKey.Language]: 'idioma',
    [OnboardingLanguageKey.Context]: 'contexto',
    [OnboardingLanguageKey.Comfortable]: 'cómodo',
    [OnboardingLanguageKey.ItsSimple]: 'Es simple',
    [OnboardingLanguageKey.And]: 'y',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'navega como haces normalmente',
    [OnboardingLanguageKey.ToBegin]: 'para empezar',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'pasa tu cursor sobre cualquier traducción',
    [OnboardingLanguageKey.ToHearPronunciations]: 'para escuchar pronunciaciones',
    [OnboardingLanguageKey.Later]: 'más tarde',
    [OnboardingLanguageKey.ViewYourProgress]: 'ver tu progreso',
    [OnboardingLanguageKey.Even]: 'incluso',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'checar tu fluidez en vocabulario',
    [OnboardingLanguageKey.With]: 'con',
    [OnboardingLanguageKey.Inline]: 'en línea',
    [OnboardingLanguageKey.Quizzes]: 'cuestionarios',
    [OnboardingLanguageKey.NeedABreak]: '¿Necesitas un descanso?',
    [OnboardingLanguageKey.Pause]: 'Pausa',
    [OnboardingLanguageKey.From]: 'desde',
    [OnboardingLanguageKey.Browser]: 'navegador',
    [OnboardingLanguageKey.Or]: 'ó',
    [HomeLanguageKey.Spanish]: 'español',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'idioma',
    [HomeLanguageKey.Easy]: 'fácil',
    [HomeLanguageKey.Words]: 'palabras',
    [HomeLanguageKey.Games]: 'juegos',
    [HomeLanguageKey.Progress]: 'progreso',
    [HomeLanguageKey.Geniuses]: 'genios',
    [RedeemLanguageKey.Key]: 'llave',
    [RedeemLanguageKey.New]: 'nuevo',
    [RedeemLanguageKey.Anything]: 'cualquier cosa',
    [MiniGamesLanguageKey.Interesting]: 'Interesante',
    [MiniGamesLanguageKey.Artist]: 'artista',
    [MiniGamesLanguageKey.VeryGood]: 'Muy bien!',
  },
  [LanguageId.Fr]: {
    [SubscriptionLanguageKey.Subscription]: 'abonnement',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'va automatiquement traduire',
    [OnboardingLanguageKey.For]: 'pour',
    [OnboardingLanguageKey.While]: 'pendant que',
    [OnboardingLanguageKey.Crucial]: 'crucial',
    [OnboardingLanguageKey.Successfully]: 'avec succès',
    [OnboardingLanguageKey.VisitAWebsite]: 'visitez un site web',
    [OnboardingLanguageKey.CertainWords]: 'certains mots',
    [OnboardingLanguageKey.Page]: 'page',
    [OnboardingLanguageKey.Learn]: 'apprendre',
    [OnboardingLanguageKey.YouCanLearn]: 'vous pouvez apprendre',
    [OnboardingLanguageKey.Language]: 'langue',
    [OnboardingLanguageKey.Context]: 'contexte',
    [OnboardingLanguageKey.Comfortable]: "à l'aise",
    [OnboardingLanguageKey.ItsSimple]: 'Il est très simple',
    [OnboardingLanguageKey.And]: 'et',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'naviguer comme vous le faites normalement',
    [OnboardingLanguageKey.ToBegin]: 'pour commencer',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'placer votre curseur sur n’importe quelle traduction',
    [OnboardingLanguageKey.ToHearPronunciations]: 'pour entendre la prononciation',
    [OnboardingLanguageKey.Later]: 'plus tard',
    [OnboardingLanguageKey.ViewYourProgress]: 'afficher vos progrès',
    [OnboardingLanguageKey.Even]: 'même',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'vérifier votre maîtrise',
    [OnboardingLanguageKey.With]: 'avec',
    [OnboardingLanguageKey.Inline]: '', // NOTE: No FR equivalent
    [OnboardingLanguageKey.Quizzes]: 'des tests',
    [OnboardingLanguageKey.NeedABreak]: 'Vous avez besoin d’une pause ?',
    [OnboardingLanguageKey.Pause]: 'Mettez en pause',
    [OnboardingLanguageKey.From]: 'à partir de',
    [OnboardingLanguageKey.Browser]: 'navigateur',
    [OnboardingLanguageKey.Or]: 'ou',
    [HomeLanguageKey.French]: 'le français',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'langue',
    [HomeLanguageKey.Easy]: 'facile',
    [HomeLanguageKey.Words]: 'mots',
    [HomeLanguageKey.Games]: 'jeux',
    [HomeLanguageKey.Progress]: 'progrès',
    [HomeLanguageKey.Geniuses]: 'genies',
    [RedeemLanguageKey.Key]: 'clé',
    [RedeemLanguageKey.New]: 'nouvelle',
    [RedeemLanguageKey.Anything]: 'tout',
    [MiniGamesLanguageKey.Interesting]: 'Intéressant',
    [MiniGamesLanguageKey.Artist]: 'artiste',
    [MiniGamesLanguageKey.VeryGood]: 'Très bien!',
  },
  [LanguageId.De]: {
    [SubscriptionLanguageKey.Subscription]: 'Abonnement',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'übersetzt automatisch',
    [OnboardingLanguageKey.For]: 'für',
    [OnboardingLanguageKey.While]: 'während',
    [OnboardingLanguageKey.Crucial]: 'entscheidend',
    [OnboardingLanguageKey.Successfully]: 'erfolgreich',
    [OnboardingLanguageKey.VisitAWebsite]: 'eine Website besuchen',
    [OnboardingLanguageKey.CertainWords]: 'bestimmte Wörter',
    [OnboardingLanguageKey.Page]: 'Seite',
    [OnboardingLanguageKey.Learn]: 'lernen',
    [OnboardingLanguageKey.YouCanLearn]: 'du kannst lernen',
    [OnboardingLanguageKey.Language]: 'Sprache',
    [OnboardingLanguageKey.Context]: 'Kontext',
    [OnboardingLanguageKey.Comfortable]: 'vertraut',
    [OnboardingLanguageKey.ItsSimple]: 'Es ist einfach',
    [OnboardingLanguageKey.And]: 'und',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'browsen, wie du es normalerweise tust',
    [OnboardingLanguageKey.ToBegin]: 'zu beginnen',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Halte den Mauszeiger über beliebige Übersetzungen',
    [OnboardingLanguageKey.ToHearPronunciations]: 'um die Aussprache zu hören',
    [OnboardingLanguageKey.Later]: 'später',
    [OnboardingLanguageKey.ViewYourProgress]: 'deinen Fortschritt zu sehen',
    [OnboardingLanguageKey.Even]: 'und auch',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'deine Wortgewandtheit zu prüfen',
    [OnboardingLanguageKey.With]: 'mit',
    [OnboardingLanguageKey.Inline]: 'Inline',
    [OnboardingLanguageKey.Quizzes]: 'Tests',
    [OnboardingLanguageKey.NeedABreak]: 'Brauchst du eine Pause?',
    [OnboardingLanguageKey.Pause]: 'Pausiere',
    [OnboardingLanguageKey.From]: 'vom',
    [OnboardingLanguageKey.Browser]: 'Browser',
    [OnboardingLanguageKey.Or]: 'oder',
    [HomeLanguageKey.German]: 'Deutsch',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'Sprache',
    [HomeLanguageKey.Easy]: 'einfach',
    [HomeLanguageKey.Words]: 'Wörter',
    [HomeLanguageKey.Games]: 'Spiele',
    [HomeLanguageKey.Progress]: 'Fortschritt',
    [HomeLanguageKey.Geniuses]: 'Genies',
    [RedeemLanguageKey.Key]: 'Schlüssel',
    [RedeemLanguageKey.New]: 'neue',
    [RedeemLanguageKey.Anything]: 'alles',
    [MiniGamesLanguageKey.Interesting]: 'Interessant',
    [MiniGamesLanguageKey.Artist]: 'Künstler',
    [MiniGamesLanguageKey.VeryGood]: 'Sehr gut!',
  },
  [LanguageId.It]: {
    [SubscriptionLanguageKey.Subscription]: 'sottoscrizione',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'tradurrà automaticamente',
    [OnboardingLanguageKey.For]: 'per',
    [OnboardingLanguageKey.While]: 'mentre',
    [OnboardingLanguageKey.Crucial]: 'cruciale',
    [OnboardingLanguageKey.Successfully]: 'con successo',
    [OnboardingLanguageKey.VisitAWebsite]: 'visiti un sito web',
    [OnboardingLanguageKey.CertainWords]: 'alcune parole',
    [OnboardingLanguageKey.Page]: 'pagina',
    [OnboardingLanguageKey.Learn]: 'imparare',
    [OnboardingLanguageKey.YouCanLearn]: 'puoi imparare',
    [OnboardingLanguageKey.Language]: 'lingua',
    [OnboardingLanguageKey.Context]: 'contesto',
    [OnboardingLanguageKey.Comfortable]: 'a tuo agio',
    [OnboardingLanguageKey.ItsSimple]: 'è facile',
    [OnboardingLanguageKey.And]: 'e',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'naviga come fai di solito',
    [OnboardingLanguageKey.ToBegin]: 'per cominciare',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'spostare il mouse sopra ogni traduzione',
    [OnboardingLanguageKey.ToHearPronunciations]: 'per ascoltare le pronunce',
    [OnboardingLanguageKey.Later]: 'più tardi',
    [OnboardingLanguageKey.ViewYourProgress]: 'vedere i tuoi progressi',
    [OnboardingLanguageKey.Even]: 'anche',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'verificare la tua padronanza delle parole',
    [OnboardingLanguageKey.With]: 'con',
    [OnboardingLanguageKey.Inline]: '', // NOTE: No IT equivalent
    [OnboardingLanguageKey.Quizzes]: 'quiz',
    [OnboardingLanguageKey.NeedABreak]: 'Serve una pausa?',
    [OnboardingLanguageKey.Pause]: 'Sospendi',
    [OnboardingLanguageKey.From]: 'dalla',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'o',
    [HomeLanguageKey.Italian]: `l'italiano`,
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'lingua',
    [HomeLanguageKey.Easy]: 'facile',
    [HomeLanguageKey.Words]: 'parole',
    [HomeLanguageKey.Games]: 'giochi',
    [HomeLanguageKey.Progress]: 'progresso',
    [HomeLanguageKey.Geniuses]: 'geni',
    [RedeemLanguageKey.Key]: 'chiave',
    [RedeemLanguageKey.New]: 'nuova',
    [RedeemLanguageKey.Anything]: 'qualsiasi',
    [MiniGamesLanguageKey.Interesting]: 'Interessante',
    [MiniGamesLanguageKey.Artist]: 'artista',
    [MiniGamesLanguageKey.VeryGood]: 'Molto bene!',
  },
  [LanguageId.Ja]: {
    [SubscriptionLanguageKey.Subscription]: 'こうどく',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'じどうてきにやくす',
    [OnboardingLanguageKey.For]: 'ために',
    [OnboardingLanguageKey.While]: 'あいだに',
    [OnboardingLanguageKey.Crucial]: 'かんじん',
    [OnboardingLanguageKey.Successfully]: 'うまく',
    [OnboardingLanguageKey.VisitAWebsite]: 'ウェブサイトにいく',
    [OnboardingLanguageKey.CertainWords]: 'とくていのことば',
    [OnboardingLanguageKey.Page]: 'ページ',
    [OnboardingLanguageKey.Learn]: 'まなぶ',
    [OnboardingLanguageKey.YouCanLearn]: 'あなたはまなべる',
    [OnboardingLanguageKey.Language]: 'げんご',
    [OnboardingLanguageKey.Context]: 'ぶんみゃく',
    [OnboardingLanguageKey.Comfortable]: 'なれている',
    [OnboardingLanguageKey.ItsSimple]: 'かんたんです',
    [OnboardingLanguageKey.And]: 'そして',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'いつものようにブラウズする',
    [OnboardingLanguageKey.ToBegin]: 'はじめる',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'やくのうえにカーソルをもってくる',
    [OnboardingLanguageKey.ToHearPronunciations]: 'はつおんをきく',
    [OnboardingLanguageKey.Later]: 'あとで',
    [OnboardingLanguageKey.ViewYourProgress]: 'しんどをみる',
    [OnboardingLanguageKey.Even]: 'しかも',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'たんごりゅうちょうどをチェックする',
    [OnboardingLanguageKey.With]: 'いっしょに',
    [OnboardingLanguageKey.Inline]: 'インライン',
    [OnboardingLanguageKey.Quizzes]: 'クイズ',
    [OnboardingLanguageKey.NeedABreak]: 'きゅうけいがひつようですか？',
    [OnboardingLanguageKey.Pause]: 'いちじていしする',
    [OnboardingLanguageKey.From]: 'から',
    [OnboardingLanguageKey.Browser]: 'ブラウザ',
    [OnboardingLanguageKey.Or]: 'または',
    [HomeLanguageKey.Japanese]: 'にほんご',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'げんご',
    [HomeLanguageKey.Easy]: 'かんたん',
    [HomeLanguageKey.Words]: 'たんご',
    [HomeLanguageKey.Games]: 'ゲーム',
    [HomeLanguageKey.Progress]: 'すすみぐあい',
    [HomeLanguageKey.Geniuses]: 'てんさい',
    [RedeemLanguageKey.Key]: 'かぎ',
    [RedeemLanguageKey.New]: 'あたらしい',
    [RedeemLanguageKey.Anything]: 'なんでも',
    [MiniGamesLanguageKey.Interesting]: 'おもしろい',
    [MiniGamesLanguageKey.Artist]: 'アーティスト',
    [MiniGamesLanguageKey.VeryGood]: 'じょうでき!',
  },
  [LanguageId.JaKanji]: {
    [SubscriptionLanguageKey.Subscription]: '購読',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: '自動的に訳す',
    [OnboardingLanguageKey.For]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.While]: '間に',
    [OnboardingLanguageKey.Crucial]: '肝心',
    [OnboardingLanguageKey.Successfully]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.VisitAWebsite]: 'ウェブサイトに行く',
    [OnboardingLanguageKey.CertainWords]: '特定の言葉',
    [OnboardingLanguageKey.Page]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.Learn]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.YouCanLearn]: 'あなたは学べる',
    [OnboardingLanguageKey.Language]: '言語',
    [OnboardingLanguageKey.Context]: '文脈',
    [OnboardingLanguageKey.Comfortable]: '慣れている',
    [OnboardingLanguageKey.ItsSimple]: '簡単です',
    [OnboardingLanguageKey.And]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.ToBegin]: '始める',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: '訳の上にカーソルを持ってくる',
    [OnboardingLanguageKey.ToHearPronunciations]: '発音を聞く',
    [OnboardingLanguageKey.Later]: '後で',
    [OnboardingLanguageKey.ViewYourProgress]: '進度を見る',
    [OnboardingLanguageKey.Even]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.CheckYourWordFluency]: '単語流暢度をチェックする',
    [OnboardingLanguageKey.With]: '一緒に',
    [OnboardingLanguageKey.Inline]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.Quizzes]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.NeedABreak]: '休憩が必要ですか？',
    [OnboardingLanguageKey.Pause]: '一時停止する',
    [OnboardingLanguageKey.From]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.Browser]: '', // NOTE: No kanji equivalent
    [OnboardingLanguageKey.Or]: '又は',
    [HomeLanguageKey.Japanese]: '日本語',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: '言語',
    [HomeLanguageKey.Easy]: '簡単',
    [HomeLanguageKey.Words]: '単語',
    [HomeLanguageKey.Games]: '', // NOTE: No kanji equivalent
    [HomeLanguageKey.Progress]: '進み具合',
    [HomeLanguageKey.Geniuses]: '', // NOTE: No kanji equivalent
    [RedeemLanguageKey.Key]: '鍵',
    [RedeemLanguageKey.New]: '新しい',
    [RedeemLanguageKey.Anything]: '何でも',
    [MiniGamesLanguageKey.Interesting]: '面白い',
    [MiniGamesLanguageKey.Artist]: '', // NOTE: No kanji equivalent
    [MiniGamesLanguageKey.VeryGood]: '上出来!',
  },
  [LanguageId.JaRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'koudoku',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'jidoutekini yakusu',
    [OnboardingLanguageKey.For]: 'tameni',
    [OnboardingLanguageKey.While]: 'aida ni',
    [OnboardingLanguageKey.Crucial]: 'kanjin',
    [OnboardingLanguageKey.Successfully]: 'umaku',
    [OnboardingLanguageKey.VisitAWebsite]: 'webusaito ni iku',
    [OnboardingLanguageKey.CertainWords]: 'tokutei no kotoba',
    [OnboardingLanguageKey.Page]: 'Pēji',
    [OnboardingLanguageKey.Learn]: 'manabu',
    [OnboardingLanguageKey.YouCanLearn]: 'anata wa manaberu',
    [OnboardingLanguageKey.Language]: 'gengo',
    [OnboardingLanguageKey.Context]: 'bunmyaku',
    [OnboardingLanguageKey.Comfortable]: 'narete iru',
    [OnboardingLanguageKey.ItsSimple]: 'kantan desu',
    [OnboardingLanguageKey.And]: 'soshite',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'itsumono youni burauzu suru',
    [OnboardingLanguageKey.ToBegin]: 'hajimeru',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Yakuno ueni kāsoru wo mottekuru',
    [OnboardingLanguageKey.ToHearPronunciations]: 'hatsuon wo kiku',
    [OnboardingLanguageKey.Later]: 'ato de',
    [OnboardingLanguageKey.ViewYourProgress]: 'shindo wo miru',
    [OnboardingLanguageKey.Even]: 'shikamo',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Tango ryuuchoudo wo chekku suru',
    [OnboardingLanguageKey.With]: 'issho ni',
    [OnboardingLanguageKey.Inline]: 'inrain',
    [OnboardingLanguageKey.Quizzes]: 'kuizu',
    [OnboardingLanguageKey.NeedABreak]: 'Kyuukei ga hitsuyou desuka?',
    [OnboardingLanguageKey.Pause]: 'ichiji teishi suru',
    [OnboardingLanguageKey.From]: 'kara',
    [OnboardingLanguageKey.Browser]: 'burauza',
    [OnboardingLanguageKey.Or]: 'matawa',
    [HomeLanguageKey.Japanese]: 'nihongo',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'gengo',
    [HomeLanguageKey.Easy]: 'kantan',
    [HomeLanguageKey.Words]: 'tango',
    [HomeLanguageKey.Games]: 'gēmu',
    [HomeLanguageKey.Progress]: 'susumi guai',
    [HomeLanguageKey.Geniuses]: 'tensai',
    [RedeemLanguageKey.Key]: 'kī',
    [RedeemLanguageKey.New]: 'atarashii',
    [RedeemLanguageKey.Anything]: 'nandemo',
    [MiniGamesLanguageKey.Interesting]: 'omoshiroi',
    [MiniGamesLanguageKey.Artist]: 'ātisuto',
    [MiniGamesLanguageKey.VeryGood]: 'joudeki!',
  },
  [LanguageId.Ko]: {
    [SubscriptionLanguageKey.Subscription]: '구독',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: '자동으로 번역',
    [OnboardingLanguageKey.For]: '위해',
    [OnboardingLanguageKey.While]: '동안',
    [OnboardingLanguageKey.Crucial]: '중요합니다',
    [OnboardingLanguageKey.Successfully]: '성공하려면',
    [OnboardingLanguageKey.VisitAWebsite]: '웹사이트를 방문',
    [OnboardingLanguageKey.CertainWords]: '특정 단어',
    [OnboardingLanguageKey.Page]: '웹페이지',
    [OnboardingLanguageKey.Learn]: '학습하기',
    [OnboardingLanguageKey.YouCanLearn]: '배울 수 있습니다',
    [OnboardingLanguageKey.Language]: '언어',
    [OnboardingLanguageKey.Context]: '문맥',
    [OnboardingLanguageKey.Comfortable]: '익숙한',
    [OnboardingLanguageKey.ItsSimple]: '간단합니다',
    [OnboardingLanguageKey.And]: '~고/그리고',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: '평소처럼 둘러 보세요.',
    [OnboardingLanguageKey.ToBegin]: '시작됩니다',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: '번역에 마우스를 가져가면',
    [OnboardingLanguageKey.ToHearPronunciations]: '발음을 듣고',
    [OnboardingLanguageKey.Later]: '나중에',
    [OnboardingLanguageKey.ViewYourProgress]: '진척 상황을 확인',
    [OnboardingLanguageKey.Even]: '심지어',
    [OnboardingLanguageKey.CheckYourWordFluency]: '단어 유창성을 확인',
    [OnboardingLanguageKey.With]: '통해',
    [OnboardingLanguageKey.Inline]: '인라인',
    [OnboardingLanguageKey.Quizzes]: '퀴즈',
    [OnboardingLanguageKey.NeedABreak]: '휴식 시간이 필요하세요?',
    [OnboardingLanguageKey.Pause]: '잠시 멈춤',
    [OnboardingLanguageKey.From]: '를',
    [OnboardingLanguageKey.Browser]: '브라우저',
    [OnboardingLanguageKey.Or]: '또는',
    [HomeLanguageKey.Korean]: '한국어',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: '언어',
    [HomeLanguageKey.Easy]: '쉽게',
    [HomeLanguageKey.Words]: '단어들',
    [HomeLanguageKey.Games]: '게임들',
    [HomeLanguageKey.Progress]: '진도',
    [HomeLanguageKey.Geniuses]: '천재들',
    [RedeemLanguageKey.Key]: '열쇠',
    [RedeemLanguageKey.New]: '새로운',
    [RedeemLanguageKey.Anything]: '무엇이든지',
    [MiniGamesLanguageKey.Interesting]: '흥미로운',
    [MiniGamesLanguageKey.Artist]: '예술가',
    [MiniGamesLanguageKey.VeryGood]: '잘 했어!',
  },
  [LanguageId.KoRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'gudok',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'Jadongeuro Beonyuk',
    [OnboardingLanguageKey.For]: 'Wehae',
    [OnboardingLanguageKey.While]: 'Dongahn',
    [OnboardingLanguageKey.Crucial]: 'Joongyohamneeda',
    [OnboardingLanguageKey.Successfully]: 'Seonggongjeokeuro',
    [OnboardingLanguageKey.VisitAWebsite]: 'Websitereul bangmun',
    [OnboardingLanguageKey.CertainWords]: 'Teukjeong daneo',
    [OnboardingLanguageKey.Page]: 'Page',
    [OnboardingLanguageKey.Learn]: 'Baewooryoneun',
    [OnboardingLanguageKey.YouCanLearn]: 'Baewool su itseumneeda',
    [OnboardingLanguageKey.Language]: 'Eoneo',
    [OnboardingLanguageKey.Context]: 'Munmaek',
    [OnboardingLanguageKey.Comfortable]: 'pyunahnhaejin',
    [OnboardingLanguageKey.ItsSimple]: 'Gandangamneeda',
    [OnboardingLanguageKey.And]: 'Geurigo',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'Pyungsocheorum dulreo boseyo',
    [OnboardingLanguageKey.ToBegin]: 'shijakharyymyun',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Beonyuke mousereul gajeogamyun',
    [OnboardingLanguageKey.ToHearPronunciations]: 'Baleumeul deutgo',
    [OnboardingLanguageKey.Later]: 'Najoongeul wehae',
    [OnboardingLanguageKey.ViewYourProgress]: 'Jincheok sanghwangeul hwakin',
    [OnboardingLanguageKey.Even]: 'Simjieo',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Daneo yuchangseongeul hwakin',
    [OnboardingLanguageKey.With]: 'Reul tonghae',
    [OnboardingLanguageKey.Inline]: 'Inline',
    [OnboardingLanguageKey.Quizzes]: 'Quizz',
    [OnboardingLanguageKey.NeedABreak]: 'Hyusik siganyi pilyohaseo?',
    [OnboardingLanguageKey.Pause]: 'Jamsi meonchupneeda',
    [OnboardingLanguageKey.From]: 'Reul',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'tto-neun',
    [HomeLanguageKey.Korean]: 'Hangugeo',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'eoneo',
    [HomeLanguageKey.Easy]: 'swipge',
    [HomeLanguageKey.Words]: 'daneodeul',
    [HomeLanguageKey.Games]: 'geimdeul',
    [HomeLanguageKey.Progress]: 'jindo',
    [HomeLanguageKey.Geniuses]: 'cheonjaedeul',
    [RedeemLanguageKey.Key]: 'yeolsoe',
    [RedeemLanguageKey.New]: 'saeroun',
    [RedeemLanguageKey.Anything]: 'mueosideunji',
    [MiniGamesLanguageKey.Interesting]: 'heungmiloun',
    [MiniGamesLanguageKey.Artist]: 'yesulga',
    [MiniGamesLanguageKey.VeryGood]: 'jal haesseo!',
  },
  [LanguageId.Pt]: {
    [SubscriptionLanguageKey.Subscription]: 'subscrição',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'irá traduzir automaticamente',
    [OnboardingLanguageKey.For]: 'para',
    [OnboardingLanguageKey.While]: 'enquanto',
    [OnboardingLanguageKey.Crucial]: 'crucial',
    [OnboardingLanguageKey.Successfully]: 'com sucesso',
    [OnboardingLanguageKey.VisitAWebsite]: 'visita um site',
    [OnboardingLanguageKey.CertainWords]: 'certas palavras',
    [OnboardingLanguageKey.Page]: 'página',
    [OnboardingLanguageKey.Learn]: 'aprender',
    [OnboardingLanguageKey.YouCanLearn]: 'você pode aprender',
    [OnboardingLanguageKey.Language]: 'língua',
    [OnboardingLanguageKey.Context]: 'contexto',
    [OnboardingLanguageKey.Comfortable]: 'conhece',
    [OnboardingLanguageKey.ItsSimple]: 'é simples',
    [OnboardingLanguageKey.And]: 'e',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'navegue como você faz normalmente',
    [OnboardingLanguageKey.ToBegin]: 'para começar',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'passar o mouse sobre as traduções',
    [OnboardingLanguageKey.ToHearPronunciations]: 'para ouvir a pronúncia',
    [OnboardingLanguageKey.Later]: 'mais tarde',
    [OnboardingLanguageKey.ViewYourProgress]: 'conferir o seu progresso',
    [OnboardingLanguageKey.Even]: 'até',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'verificar sua fluência de palavras',
    [OnboardingLanguageKey.With]: 'com',
    [OnboardingLanguageKey.Inline]: 'em sequência',
    [OnboardingLanguageKey.Quizzes]: 'testes',
    [OnboardingLanguageKey.NeedABreak]: 'Precisa de uma pausa?',
    [OnboardingLanguageKey.Pause]: 'Pause',
    [OnboardingLanguageKey.From]: 'na',
    [OnboardingLanguageKey.Browser]: 'navegador',
    [OnboardingLanguageKey.Or]: 'ou',
    [HomeLanguageKey.Portuguese]: 'português',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'idioma',
    [HomeLanguageKey.Easy]: 'fácil',
    [HomeLanguageKey.Words]: 'palavras',
    [HomeLanguageKey.Games]: 'jogos',
    [HomeLanguageKey.Progress]: 'progresso',
    [HomeLanguageKey.Geniuses]: 'gênios',
    [RedeemLanguageKey.Key]: 'chave',
    [RedeemLanguageKey.New]: 'nova',
    [RedeemLanguageKey.Anything]: '',
    [MiniGamesLanguageKey.Interesting]: 'Interessante',
    [MiniGamesLanguageKey.Artist]: 'artista',
    [MiniGamesLanguageKey.VeryGood]: 'Muito bom!',
  },
  [LanguageId.Zh]: {
    [SubscriptionLanguageKey.Subscription]: '订阅',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: '会自动翻译',
    [OnboardingLanguageKey.For]: '给',
    [OnboardingLanguageKey.While]: '当',
    [OnboardingLanguageKey.Crucial]: '关键的',
    [OnboardingLanguageKey.Successfully]: '成功地',
    [OnboardingLanguageKey.VisitAWebsite]: '访问一个网站',
    [OnboardingLanguageKey.CertainWords]: '某些词语',
    [OnboardingLanguageKey.Page]: '页面',
    [OnboardingLanguageKey.Learn]: '学习',
    [OnboardingLanguageKey.YouCanLearn]: '你会学习',
    [OnboardingLanguageKey.Language]: '语言',
    [OnboardingLanguageKey.Context]: '上下文',
    [OnboardingLanguageKey.Comfortable]: '舒适',
    [OnboardingLanguageKey.ItsSimple]: '它很简单',
    [OnboardingLanguageKey.And]: '和',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: '像你平常一样浏览',
    [OnboardingLanguageKey.ToBegin]: '开始',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: '将鼠标停在任何翻译上',
    [OnboardingLanguageKey.ToHearPronunciations]: '听到发音',
    [OnboardingLanguageKey.Later]: '以后',
    [OnboardingLanguageKey.ViewYourProgress]: '查看你的进度',
    [OnboardingLanguageKey.Even]: '甚至',
    [OnboardingLanguageKey.CheckYourWordFluency]: '查看你的词汇流畅性',
    [OnboardingLanguageKey.With]: '和',
    [OnboardingLanguageKey.Inline]: '内联',
    [OnboardingLanguageKey.Quizzes]: '测验',
    [OnboardingLanguageKey.NeedABreak]: '需要休息一下吗？',
    [OnboardingLanguageKey.Pause]: '暂停',
    [OnboardingLanguageKey.From]: '从',
    [OnboardingLanguageKey.Browser]: '浏览器',
    [OnboardingLanguageKey.Or]: '或者',
    [HomeLanguageKey.Chinese]: '中文',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: '语言',
    [HomeLanguageKey.Easy]: '简单',
    [HomeLanguageKey.Words]: '单词',
    [HomeLanguageKey.Games]: '游戏',
    [HomeLanguageKey.Progress]: '进度',
    [HomeLanguageKey.Geniuses]: '天才',
    [RedeemLanguageKey.Key]: '钥匙',
    [RedeemLanguageKey.New]: '新的',
    [RedeemLanguageKey.Anything]: '任何事物',
    [MiniGamesLanguageKey.Interesting]: '有趣的',
    [MiniGamesLanguageKey.Artist]: '艺术家',
    [MiniGamesLanguageKey.VeryGood]: '非常好',
  },
  [LanguageId.ZhRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'dìng yuè',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'huì zì dòng fān yì',
    [OnboardingLanguageKey.For]: 'gěi',
    [OnboardingLanguageKey.While]: 'dāng',
    [OnboardingLanguageKey.Crucial]: 'guān jiàn de',
    [OnboardingLanguageKey.Successfully]: 'chéng gōng de',
    [OnboardingLanguageKey.VisitAWebsite]: 'fǎng wèn yī gè wǎng zhàn',
    [OnboardingLanguageKey.CertainWords]: 'mǒu xiē cí yǔ',
    [OnboardingLanguageKey.Page]: 'yè miàn',
    [OnboardingLanguageKey.Learn]: 'xué xí',
    [OnboardingLanguageKey.YouCanLearn]: 'nǐ huì xué xí',
    [OnboardingLanguageKey.Language]: 'yǔ yán',
    [OnboardingLanguageKey.Context]: 'shàng xià wén',
    [OnboardingLanguageKey.Comfortable]: 'shū shì',
    [OnboardingLanguageKey.ItsSimple]: 'tā hěn jiǎn dān',
    [OnboardingLanguageKey.And]: 'hé',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'xiàng nǐ píng cháng yī yàng liú lǎn',
    [OnboardingLanguageKey.ToBegin]: 'kāi shǐ',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'jiāng shǔ biāo tíng zài rèn hé fān yì shàng',
    [OnboardingLanguageKey.ToHearPronunciations]: 'tīng dào fā yīn',
    [OnboardingLanguageKey.Later]: 'yǐ hòu',
    [OnboardingLanguageKey.ViewYourProgress]: 'chá kàn nǐ de jìn dù',
    [OnboardingLanguageKey.Even]: 'shèn zhì',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'chá kàn nǐ de cí huì liú chàng xìng',
    [OnboardingLanguageKey.With]: 'hé',
    [OnboardingLanguageKey.Inline]: 'nèi lián',
    [OnboardingLanguageKey.Quizzes]: 'cè yàn',
    [OnboardingLanguageKey.NeedABreak]: 'Xū yào xiū xi yī xià ma?',
    [OnboardingLanguageKey.Pause]: 'zàn tíng',
    [OnboardingLanguageKey.From]: 'cóng',
    [OnboardingLanguageKey.Browser]: 'liú lǎn qì',
    [OnboardingLanguageKey.Or]: 'huò zhě',
    [HomeLanguageKey.Chinese]: 'zhōngwén',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'yǔyán',
    [HomeLanguageKey.Easy]: 'róng yì',
    [HomeLanguageKey.Words]: 'dān cí',
    [HomeLanguageKey.Games]: 'yóu xì',
    [HomeLanguageKey.Progress]: 'jìn dù',
    [HomeLanguageKey.Geniuses]: 'tiān cái',
    [RedeemLanguageKey.Key]: 'yào shi',
    [RedeemLanguageKey.New]: 'xīn de',
    [RedeemLanguageKey.Anything]: 'rèn hé shì wù',
    [MiniGamesLanguageKey.Interesting]: 'yǒu qù de',
    [MiniGamesLanguageKey.Artist]: 'yì shù jiā',
    [MiniGamesLanguageKey.VeryGood]: 'fēi cháng hǎo!',
  },
  [LanguageId.Ar]: {
    [SubscriptionLanguageKey.Subscription]: 'اشتراك',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'سيترجم تلقائياً',
    [OnboardingLanguageKey.For]: '', // NOTE: No AR equivalent
    [OnboardingLanguageKey.While]: 'بينما',
    [OnboardingLanguageKey.Crucial]: 'ضروري',
    [OnboardingLanguageKey.Successfully]: 'بنجاح',
    [OnboardingLanguageKey.VisitAWebsite]: 'تزور موقع إلكتروني',
    [OnboardingLanguageKey.CertainWords]: 'كلمات معينة',
    [OnboardingLanguageKey.Page]: 'صفحة',
    [OnboardingLanguageKey.Learn]: 'تعلّم',
    [OnboardingLanguageKey.YouCanLearn]: 'يمكنك أن تتعلم',
    [OnboardingLanguageKey.Language]: 'لغة',
    [OnboardingLanguageKey.Context]: 'سياق',
    [OnboardingLanguageKey.Comfortable]: 'مرتاح',
    [OnboardingLanguageKey.ItsSimple]: 'الأمر بسيط',
    [OnboardingLanguageKey.And]: 'و',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'تصّفح كما تفعل عادة',
    [OnboardingLanguageKey.ToBegin]: 'لتبدأ',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'مرّر الماوس فوق أي ترجمات',
    [OnboardingLanguageKey.ToHearPronunciations]: 'لتسمع اللفظ',
    [OnboardingLanguageKey.Later]: 'لاحقاً',
    [OnboardingLanguageKey.ViewYourProgress]: 'شاهد تقّدمك',
    [OnboardingLanguageKey.Even]: 'حتى',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'لتتحقق من طلاقة كلمتك',
    [OnboardingLanguageKey.With]: 'مع',
    [OnboardingLanguageKey.Inline]: 'مضمّنة',
    [OnboardingLanguageKey.Quizzes]: 'إختبارات',
    [OnboardingLanguageKey.NeedABreak]: 'هل تحتاج إلى إستراحة؟',
    [OnboardingLanguageKey.Pause]: 'أوقف',
    [OnboardingLanguageKey.From]: 'من',
    [OnboardingLanguageKey.Browser]: 'متصفّح',
    [OnboardingLanguageKey.Or]: 'أو',
    [HomeLanguageKey.Arabic]: 'اللغة العربية',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'لغة',
    [HomeLanguageKey.Easy]: 'سهلاً',
    [HomeLanguageKey.Words]: 'كلمات',
    [HomeLanguageKey.Games]: 'ألعاب',
    [HomeLanguageKey.Progress]: 'تقدّمك',
    [HomeLanguageKey.Geniuses]: 'نوابغ',
    [RedeemLanguageKey.Key]: 'مفتاح',
    [RedeemLanguageKey.New]: 'جديدة',
    [RedeemLanguageKey.Anything]: 'أي شيء',
    [MiniGamesLanguageKey.Interesting]: 'مثير للاهتمام',
    [MiniGamesLanguageKey.Artist]: 'فنان',
    [MiniGamesLanguageKey.VeryGood]: 'جيد جداً',
  },
  [LanguageId.ArRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'Ishtiraak',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'Sayutarjim tilqaa’iyyan',
    [OnboardingLanguageKey.For]: '', // NOTE: No ArRoman equivalent
    [OnboardingLanguageKey.While]: 'Baynama',
    [OnboardingLanguageKey.Crucial]: 'Daroori',
    [OnboardingLanguageKey.Successfully]: 'Bi Najaah',
    [OnboardingLanguageKey.VisitAWebsite]: 'Tazoor mawqi’ elektrony',
    [OnboardingLanguageKey.CertainWords]: 'Kalimaat mu’ayyanah',
    [OnboardingLanguageKey.Page]: 'Safhah',
    [OnboardingLanguageKey.Learn]: 'Ta’llam',
    [OnboardingLanguageKey.YouCanLearn]: 'Yumkinua an tata’allama',
    [OnboardingLanguageKey.Language]: 'Loughah',
    [OnboardingLanguageKey.Context]: 'Siyaaq',
    [OnboardingLanguageKey.Comfortable]: 'Murtaah',
    [OnboardingLanguageKey.ItsSimple]: 'Al-amr baseet',
    [OnboardingLanguageKey.And]: 'Wa',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'Tassafah kama taf’al ‘aadatan',
    [OnboardingLanguageKey.ToBegin]: 'Li Tabda’',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Marrir al-maws fawqa ay tarjamaat',
    [OnboardingLanguageKey.ToHearPronunciations]: 'Litasma’ al-lafdh',
    [OnboardingLanguageKey.Later]: 'Laahiqan',
    [OnboardingLanguageKey.ViewYourProgress]: 'Shahid taqaddumika',
    [OnboardingLanguageKey.Even]: 'Hatta',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Litatahaqqaq min talaaqat kalimatuka',
    [OnboardingLanguageKey.With]: 'Ma’a',
    [OnboardingLanguageKey.Inline]: 'Mudammanah',
    [OnboardingLanguageKey.Quizzes]: 'Ikhtibaraat',
    [OnboardingLanguageKey.NeedABreak]: 'Hal tahtaaj ila istiraahah?',
    [OnboardingLanguageKey.Pause]: 'Awqif',
    [OnboardingLanguageKey.From]: 'Min',
    [OnboardingLanguageKey.Browser]: 'Mutsaffih',
    [OnboardingLanguageKey.Or]: 'Aw',
    [HomeLanguageKey.Arabic]: 'Al-Lughah Al-Arabiyyah',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'Lughah',
    [HomeLanguageKey.Easy]: 'Sahlan',
    [HomeLanguageKey.Words]: 'Kalimaat',
    [HomeLanguageKey.Games]: 'Al’aab',
    [HomeLanguageKey.Progress]: 'Taqaddumka',
    [HomeLanguageKey.Geniuses]: 'Nawaabigh',
    [RedeemLanguageKey.Key]: 'Miftaah',
    [RedeemLanguageKey.New]: 'Jadeedah',
    [RedeemLanguageKey.Anything]: 'Ay Shay’',
    [MiniGamesLanguageKey.Interesting]: 'Mutheer lil ihtimaam',
    [MiniGamesLanguageKey.Artist]: 'Fannaan',
    [MiniGamesLanguageKey.VeryGood]: 'Jayyid jiddan!',
  },
  [LanguageId.He]: {
    [SubscriptionLanguageKey.Subscription]: 'מינוי',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'יתרגם באופן אוטומטי',
    [OnboardingLanguageKey.For]: 'ל',
    [OnboardingLanguageKey.While]: 'בזמן',
    [OnboardingLanguageKey.Crucial]: 'חיוני',
    [OnboardingLanguageKey.Successfully]: 'בהצלחה',
    [OnboardingLanguageKey.VisitAWebsite]: 'תבקר באתר',
    [OnboardingLanguageKey.CertainWords]: 'מילים מסוימות',
    [OnboardingLanguageKey.Page]: 'דף',
    [OnboardingLanguageKey.Learn]: 'ללמוד',
    [OnboardingLanguageKey.YouCanLearn]: 'תוכל ללמוד',
    [OnboardingLanguageKey.Language]: 'שפה',
    [OnboardingLanguageKey.Context]: 'הקשר',
    [OnboardingLanguageKey.Comfortable]: 'נוח',
    [OnboardingLanguageKey.ItsSimple]: 'זה פשוט',
    [OnboardingLanguageKey.And]: 'ו',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'עלעל כמו שאתה עושה באופן רגיל',
    [OnboardingLanguageKey.ToBegin]: 'להתחיל',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'רחף מעל כל תרגום',
    [OnboardingLanguageKey.ToHearPronunciations]: 'בכדי לשמוע הגיה',
    [OnboardingLanguageKey.Later]: 'יותר מאוחר',
    [OnboardingLanguageKey.ViewYourProgress]: 'צפה בהתקדמות שלך',
    [OnboardingLanguageKey.Even]: 'אפילו',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'לבדוק את רהיטות המילים שלך',
    [OnboardingLanguageKey.With]: 'רהיטות',
    [OnboardingLanguageKey.Inline]: 'עם',
    [OnboardingLanguageKey.Quizzes]: 'בחנים',
    [OnboardingLanguageKey.NeedABreak]: 'זקוק להפסקה?', // NOTE: question mark should be at the beginning but formatting prevents this from happening
    [OnboardingLanguageKey.Pause]: 'הפסקה',
    [OnboardingLanguageKey.From]: 'מ',
    [OnboardingLanguageKey.Browser]: 'הפסק',
    [OnboardingLanguageKey.Or]: 'דפדפן בראוזר',
    [HomeLanguageKey.Hebrew]: 'עברית',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'שפה',
    [HomeLanguageKey.Easy]: 'קל',
    [HomeLanguageKey.Words]: 'מילים',
    [HomeLanguageKey.Games]: 'משחקים',
    [HomeLanguageKey.Progress]: 'התקדמות',
    [HomeLanguageKey.Geniuses]: 'גאונים',
    [RedeemLanguageKey.Key]: 'מפתח',
    [RedeemLanguageKey.New]: 'חדשה',
    [RedeemLanguageKey.Anything]: '',
    [MiniGamesLanguageKey.Interesting]: 'מעניין',
    [MiniGamesLanguageKey.Artist]: 'אומן',
    [MiniGamesLanguageKey.VeryGood]: 'טוב מאוד',
  },
  [LanguageId.HeRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'minuy',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'y’tahrgem b’ohfen ohtomahtee',
    [OnboardingLanguageKey.For]: 'l',
    [OnboardingLanguageKey.While]: 'bzmahn',
    [OnboardingLanguageKey.Crucial]: 'cheeyoonee',
    [OnboardingLanguageKey.Successfully]: 'bhahtslahchah',
    [OnboardingLanguageKey.VisitAWebsite]: 'tvahker bah’ahtar',
    [OnboardingLanguageKey.CertainWords]: 'meeleem msooyahmot',
    [OnboardingLanguageKey.Page]: 'dahf',
    [OnboardingLanguageKey.Learn]: 'leelmod',
    [OnboardingLanguageKey.YouCanLearn]: 'toochal leelmod',
    [OnboardingLanguageKey.Language]: 'sahfah',
    [OnboardingLanguageKey.Context]: 'heksher',
    [OnboardingLanguageKey.Comfortable]: 'noahch',
    [OnboardingLanguageKey.ItsSimple]: 'zeh pahshoot',
    [OnboardingLanguageKey.And]: 'v',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'ahl’el kmo sheh’ahtah oseh b’ohfen rahgeel',
    [OnboardingLanguageKey.ToBegin]: 'le’hahtcheel',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'rah’chef meh’ahl kol teergoom',
    [OnboardingLanguageKey.ToHearPronunciations]: 'beech’day leeshmo’ah hahgahyah',
    [OnboardingLanguageKey.Later]: 'Yotehr m’oochahr',
    [OnboardingLanguageKey.ViewYourProgress]: 'tsfeh bah’heetkahdmoot shehlcah',
    [OnboardingLanguageKey.Even]: 'ahfeeloo',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'leevdok et rheetoot hameeleem shehlcah',
    [OnboardingLanguageKey.With]: 'rheetoot',
    [OnboardingLanguageKey.Inline]: 'eem',
    [OnboardingLanguageKey.Quizzes]: 'b’chahneem',
    [OnboardingLanguageKey.NeedABreak]: 'Zahkook l’hahfsahkah?',
    [OnboardingLanguageKey.Pause]: 'hahfsahkah',
    [OnboardingLanguageKey.From]: 'meh',
    [OnboardingLanguageKey.Browser]: 'hafsehk',
    [OnboardingLanguageKey.Or]: 'Dahfdehfahn brahoozer',
    [HomeLanguageKey.Hebrew]: 'ivrit',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hindi]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'safa',
    [HomeLanguageKey.Easy]: 'kal',
    [HomeLanguageKey.Words]: 'milim',
    [HomeLanguageKey.Games]: 'mischakim',
    [HomeLanguageKey.Progress]: 'hitkadmut',
    [HomeLanguageKey.Geniuses]: 'ge’onim',
    [RedeemLanguageKey.Key]: 'mafte’ach',
    [RedeemLanguageKey.New]: 'chadasha',
    [RedeemLanguageKey.Anything]: 'kol davar',
    [MiniGamesLanguageKey.Interesting]: 'meh-anyen',
    [MiniGamesLanguageKey.Artist]: 'oman',
    [MiniGamesLanguageKey.VeryGood]: 'tov meod!',
  },
  [LanguageId.Hi]: {
    [SubscriptionLanguageKey.Subscription]: 'अंशदान',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'अपने आप अनुवाद करेगा',
    [OnboardingLanguageKey.For]: 'लिए',
    [OnboardingLanguageKey.While]: 'जब',
    [OnboardingLanguageKey.Crucial]: 'महत्वपूर्ण',
    [OnboardingLanguageKey.Successfully]: 'सफलतापूर्वक',
    [OnboardingLanguageKey.VisitAWebsite]: 'किसी वेबसाइट पर जाते हैं',
    [OnboardingLanguageKey.CertainWords]: 'कुछ शब्दों',
    [OnboardingLanguageKey.Page]: 'पृष्ठ',
    [OnboardingLanguageKey.Learn]: 'सीखने',
    [OnboardingLanguageKey.YouCanLearn]: 'आप सीख सकते हैं',
    [OnboardingLanguageKey.Language]: 'भाषा',
    [OnboardingLanguageKey.Context]: 'संदर्भ',
    [OnboardingLanguageKey.Comfortable]: 'सहज',
    [OnboardingLanguageKey.ItsSimple]: 'आसान है',
    [OnboardingLanguageKey.And]: 'और',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'वैसे ही ब्राउज़ करे जैसे आप',
    [OnboardingLanguageKey.ToBegin]: 'शुरुआत करने के लिए',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'किसी भी अनुवाद पर होवर करें',
    [OnboardingLanguageKey.ToHearPronunciations]: 'उच्चारण सुनने के लिए',
    [OnboardingLanguageKey.Later]: 'बाद',
    [OnboardingLanguageKey.ViewYourProgress]: 'अपनी प्रगति को देखें',
    [OnboardingLanguageKey.Even]: 'यहाँ तक कि',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'अपने शब्द प्रवाह की जाँच करने',
    [OnboardingLanguageKey.With]: 'के साथ',
    [OnboardingLanguageKey.Inline]: 'इनलाइन',
    [OnboardingLanguageKey.Quizzes]: 'क्विज़',
    [OnboardingLanguageKey.NeedABreak]: 'ब्रेक लेने की जरूरत है?',
    [OnboardingLanguageKey.Pause]: 'रोकें',
    [OnboardingLanguageKey.From]: 'से',
    [OnboardingLanguageKey.Browser]: 'ब्राउज़र',
    [OnboardingLanguageKey.Or]: 'या',
    [HomeLanguageKey.Hindi]: 'हिंदी',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'भाषा',
    [HomeLanguageKey.Easy]: 'आसान',
    [HomeLanguageKey.Words]: 'शब्दों',
    [HomeLanguageKey.Games]: 'खेल',
    [HomeLanguageKey.Progress]: 'प्रगति',
    [HomeLanguageKey.Geniuses]: 'प्रतिभाशाले',
    [RedeemLanguageKey.Key]: 'चाबी',
    [RedeemLanguageKey.New]: 'नई',
    [RedeemLanguageKey.Anything]: 'कुछ भी',
    [MiniGamesLanguageKey.Interesting]: 'दिलचस्प',
    [MiniGamesLanguageKey.Artist]: 'कलाकार',
    [MiniGamesLanguageKey.VeryGood]: 'बहुत अच्छा',
  },
  [LanguageId.HiRoman]: {
    [SubscriptionLanguageKey.Subscription]: 'sadasyataa',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'apne aap se anuvaad karega',
    [OnboardingLanguageKey.For]: 'liye',
    [OnboardingLanguageKey.While]: 'jab',
    [OnboardingLanguageKey.Crucial]: 'mehtavpoorn',
    [OnboardingLanguageKey.Successfully]: 'safalataapoorvak',
    [OnboardingLanguageKey.VisitAWebsite]: 'kisee website par jaate hain',
    [OnboardingLanguageKey.CertainWords]: 'kuchh shabdon',
    [OnboardingLanguageKey.Page]: 'Prasth',
    [OnboardingLanguageKey.Learn]: 'seekhne',
    [OnboardingLanguageKey.YouCanLearn]: 'aap seekh sakte hain',
    [OnboardingLanguageKey.Language]: 'bhasha',
    [OnboardingLanguageKey.Context]: 'sandarbh',
    [OnboardingLanguageKey.Comfortable]: 'sehaj',
    [OnboardingLanguageKey.ItsSimple]: 'aasaan hai',
    [OnboardingLanguageKey.And]: 'aur',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'vaise hee browse karen jaise aap',
    [OnboardingLanguageKey.ToBegin]: 'shuruaat karne ke liye',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'kisee bhi anuvaad par hover karen',
    [OnboardingLanguageKey.ToHearPronunciations]: 'uchchaaran sunane ke liye',
    [OnboardingLanguageKey.Later]: 'baad',
    [OnboardingLanguageKey.ViewYourProgress]: 'apanee pragati ko dekhen',
    [OnboardingLanguageKey.Even]: 'yahan tak ki',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'apane shabd pravaah kee jaanch karen',
    [OnboardingLanguageKey.With]: 'ke saath',
    [OnboardingLanguageKey.Inline]: 'inline',
    [OnboardingLanguageKey.Quizzes]: 'quiz',
    [OnboardingLanguageKey.NeedABreak]: 'Break lene ki jaroorat hai?',
    [OnboardingLanguageKey.Pause]: 'roken',
    [OnboardingLanguageKey.From]: 'se',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'yaa',
    [HomeLanguageKey.Hindi]: 'hindi',
    [HomeLanguageKey.English]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.French]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.German]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Italian]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Japanese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Korean]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Portuguese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Spanish]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Chinese]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Arabic]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Hebrew]: 'Unused but kept for enum consistency',
    [HomeLanguageKey.Language]: 'bhaashaa',
    [HomeLanguageKey.Easy]: 'aasaan',
    [HomeLanguageKey.Words]: 'shabd',
    [HomeLanguageKey.Games]: 'khel',
    [HomeLanguageKey.Progress]: 'pragati',
    [HomeLanguageKey.Geniuses]: 'pratibhashali',
    [RedeemLanguageKey.Key]: 'chabi',
    [RedeemLanguageKey.New]: 'naya',
    [RedeemLanguageKey.Anything]: 'kuch bhi',
    [MiniGamesLanguageKey.Interesting]: 'dilachasp',
    [MiniGamesLanguageKey.Artist]: 'kalaakaar',
    [MiniGamesLanguageKey.VeryGood]: 'bahut achchhe!',
  },
};
