import { Language, LanguageCapitalized } from '~/constants/languages';

import { SubscriptionLanguageKey, OnboardingLanguageKey, HomeLanguageKey, RedeemLanguageKey } from './types';

export const pronunciations: Partial<Record<Language, Record<string, string>>> = {
  [Language.Japanese]: {
    [LanguageCapitalized.Japanese]: 'nihongo',
    [SubscriptionLanguageKey.Subscription]: 'koudoku',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'jidoutekini yakusu',
    [OnboardingLanguageKey.For]: 'tameni',
    [OnboardingLanguageKey.While]: 'aida ni',
    [OnboardingLanguageKey.Crucial]: 'kanjin',
    [OnboardingLanguageKey.Successfully]: 'umaku',
    [OnboardingLanguageKey.VisitAWebsite]: 'webusaito ni iku',
    [OnboardingLanguageKey.CertainWords]: 'tokutei no kotoba',
    [OnboardingLanguageKey.Page]: 'Pēji',
    [OnboardingLanguageKey.Learn]: 'manabu',
    [OnboardingLanguageKey.YouCanLearn]: 'anata wa manaberu',
    [OnboardingLanguageKey.Language]: 'gengo',
    [OnboardingLanguageKey.Context]: 'bunmyaku',
    [OnboardingLanguageKey.Comfortable]: 'narete iru',
    [OnboardingLanguageKey.ItsSimple]: 'kantan desu',
    [OnboardingLanguageKey.And]: 'soshite',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'itsumono youni burauzu suru',
    [OnboardingLanguageKey.ToBegin]: 'hajimeru',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Yakuno ueni kāsoru wo mottekuru',
    [OnboardingLanguageKey.ToHearPronunciations]: 'hatsuon wo kiku',
    [OnboardingLanguageKey.Later]: 'ato de',
    [OnboardingLanguageKey.ViewYourProgress]: 'shindo wo miru',
    [OnboardingLanguageKey.Even]: 'shikamo',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Tango ryuuchoudo wo chekku suru',
    [OnboardingLanguageKey.With]: 'issho ni',
    [OnboardingLanguageKey.Inline]: 'inrain',
    [OnboardingLanguageKey.Quizzes]: 'kuizu',
    [OnboardingLanguageKey.NeedABreak]: 'Kyuukei ga hitsuyou desuka?',
    [OnboardingLanguageKey.Pause]: 'ichiji teishi suru',
    [OnboardingLanguageKey.From]: 'kara',
    [OnboardingLanguageKey.Browser]: 'burauza',
    [OnboardingLanguageKey.Or]: 'matawa',
    [HomeLanguageKey.Language]: 'gengo',
    [HomeLanguageKey.Easy]: 'kantan',
    [HomeLanguageKey.Words]: 'tango',
    [HomeLanguageKey.Games]: 'gēmu',
    [HomeLanguageKey.Progress]: 'susumi guai',
    [HomeLanguageKey.Geniuses]: 'tensai',
    [RedeemLanguageKey.Key]: 'kī',
    [RedeemLanguageKey.New]: 'atarashii',
    [RedeemLanguageKey.Anything]: 'nandemo',
  },
  [Language.Korean]: {
    [LanguageCapitalized.Korean]: 'Hangugeo',
    [SubscriptionLanguageKey.Subscription]: 'gudok',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'Jadongeuro Beonyuk',
    [OnboardingLanguageKey.For]: 'Wehae',
    [OnboardingLanguageKey.While]: 'Dongahn',
    [OnboardingLanguageKey.Crucial]: 'Joongyohamneeda',
    [OnboardingLanguageKey.Successfully]: 'Seonggongjeokeuro',
    [OnboardingLanguageKey.VisitAWebsite]: 'Websitereul bangmun',
    [OnboardingLanguageKey.CertainWords]: 'Teukjeong daneo',
    [OnboardingLanguageKey.Page]: 'Page',
    [OnboardingLanguageKey.Learn]: 'Baewooryoneun',
    [OnboardingLanguageKey.YouCanLearn]: 'Baewool su itseumneeda',
    [OnboardingLanguageKey.Language]: 'Eoneo',
    [OnboardingLanguageKey.Context]: 'Munmaek',
    [OnboardingLanguageKey.Comfortable]: 'pyunahnhaejin',
    [OnboardingLanguageKey.ItsSimple]: 'Gandangamneeda',
    [OnboardingLanguageKey.And]: 'Geurigo',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'Pyungsocheorum dulreo boseyo',
    [OnboardingLanguageKey.ToBegin]: 'shijakharyymyun',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Beonyuke mousereul gajeogamyun',
    [OnboardingLanguageKey.ToHearPronunciations]: 'Baleumeul deutgo',
    [OnboardingLanguageKey.Later]: 'Najoongeul wehae',
    [OnboardingLanguageKey.ViewYourProgress]: 'Jincheok sanghwangeul hwakin',
    [OnboardingLanguageKey.Even]: 'Simjieo',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Daneo yuchangseongeul hwakin',
    [OnboardingLanguageKey.With]: 'Reul tonghae',
    [OnboardingLanguageKey.Inline]: 'Inline',
    [OnboardingLanguageKey.Quizzes]: 'Quizz',
    [OnboardingLanguageKey.NeedABreak]: 'Hyusik siganyi pilyohaseo?',
    [OnboardingLanguageKey.Pause]: 'Jamsi meonchupneeda',
    [OnboardingLanguageKey.From]: 'Reul',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'tto-neun',
    [HomeLanguageKey.Language]: 'eoneo',
    [HomeLanguageKey.Easy]: 'swipge',
    [HomeLanguageKey.Words]: 'daneodeul',
    [HomeLanguageKey.Games]: 'geimdeul',
    [HomeLanguageKey.Progress]: 'jindo',
    [HomeLanguageKey.Geniuses]: 'cheonjaedeul',
    [RedeemLanguageKey.Key]: 'yeolsoe',
    [RedeemLanguageKey.New]: 'saeroun',
    [RedeemLanguageKey.Anything]: 'mueosideunji',
  },
  [Language.Chinese]: {
    [LanguageCapitalized.Chinese]: 'zhōngwén',
    [SubscriptionLanguageKey.Subscription]: 'dìngyuè',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'huì zì dòng fān yì',
    [OnboardingLanguageKey.For]: 'gěi',
    [OnboardingLanguageKey.While]: 'dāng',
    [OnboardingLanguageKey.Crucial]: 'guān jiàn de',
    [OnboardingLanguageKey.Successfully]: 'chéng gōng de',
    [OnboardingLanguageKey.VisitAWebsite]: 'fǎng wèn yī gè wǎng zhàn',
    [OnboardingLanguageKey.CertainWords]: 'mǒu xiē cí yǔ',
    [OnboardingLanguageKey.Page]: 'yè miàn',
    [OnboardingLanguageKey.Learn]: 'xué xí',
    [OnboardingLanguageKey.YouCanLearn]: 'nǐ huì xué xí',
    [OnboardingLanguageKey.Language]: 'yǔ yán',
    [OnboardingLanguageKey.Context]: 'shàng xià wén',
    [OnboardingLanguageKey.Comfortable]: 'shū shì',
    [OnboardingLanguageKey.ItsSimple]: 'tā hěn jiǎn dān',
    [OnboardingLanguageKey.And]: 'hé',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'xiàng nǐ píng cháng yī yàng liú lǎn',
    [OnboardingLanguageKey.ToBegin]: 'kāi shǐ',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'jiāng shǔ biāo tíng zài rèn hé fān yì shàng',
    [OnboardingLanguageKey.ToHearPronunciations]: 'tīng dào fā yīn',
    [OnboardingLanguageKey.Later]: 'yǐ hòu',
    [OnboardingLanguageKey.ViewYourProgress]: 'chá kàn nǐ de jìn dù',
    [OnboardingLanguageKey.Even]: 'shèn zhì',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'chá kàn nǐ de cí huì liú chàng xìng',
    [OnboardingLanguageKey.With]: 'hé',
    [OnboardingLanguageKey.Inline]: 'nèi lián',
    [OnboardingLanguageKey.Quizzes]: 'cè yàn',
    [OnboardingLanguageKey.NeedABreak]: 'Xū yào xiū xi yī xià ma?',
    [OnboardingLanguageKey.Pause]: 'zàn tíng',
    [OnboardingLanguageKey.From]: 'cóng',
    [OnboardingLanguageKey.Browser]: 'liú lǎn qì',
    [OnboardingLanguageKey.Or]: 'huò zhě',
    [HomeLanguageKey.Language]: 'yǔyán',
    [HomeLanguageKey.Easy]: 'jiǎndān',
    [HomeLanguageKey.Words]: 'dāncí',
    [HomeLanguageKey.Games]: 'yóuxì',
    [HomeLanguageKey.Progress]: 'jìndù',
    [HomeLanguageKey.Geniuses]: 'tiāncái',
    [RedeemLanguageKey.Key]: 'yàoshi',
    [RedeemLanguageKey.New]: 'xīn',
    [RedeemLanguageKey.Anything]: 'rènhé shìwù',
  },
  [Language.Arabic]: {
    [LanguageCapitalized.Arabic]: 'Al-Lughah Al-Arabiyyah',
    [SubscriptionLanguageKey.Subscription]: 'Ishtiraak',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'Sayutarjim tilqaa’iyyan',
    [OnboardingLanguageKey.For]: '', // NOTE: No ArRoman equivalent
    [OnboardingLanguageKey.While]: 'Baynama',
    [OnboardingLanguageKey.Crucial]: 'Daroori',
    [OnboardingLanguageKey.Successfully]: 'Bi Najaah',
    [OnboardingLanguageKey.VisitAWebsite]: 'Tazoor mawqi’ elektrony',
    [OnboardingLanguageKey.CertainWords]: 'Kalimaat mu’ayyanah',
    [OnboardingLanguageKey.Page]: 'Safhah',
    [OnboardingLanguageKey.Learn]: 'Ta’llam',
    [OnboardingLanguageKey.YouCanLearn]: 'Yumkinua an tata’allama',
    [OnboardingLanguageKey.Language]: 'Loughah',
    [OnboardingLanguageKey.Context]: 'Siyaaq',
    [OnboardingLanguageKey.Comfortable]: 'Murtaah',
    [OnboardingLanguageKey.ItsSimple]: 'Al-amr baseet',
    [OnboardingLanguageKey.And]: 'Wa',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'Tassafah kama taf’al ‘aadatan',
    [OnboardingLanguageKey.ToBegin]: 'Li Tabda’',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'Marrir al-maws fawqa ay tarjamaat',
    [OnboardingLanguageKey.ToHearPronunciations]: 'Litasma’ al-lafdh',
    [OnboardingLanguageKey.Later]: 'Laahiqan',
    [OnboardingLanguageKey.ViewYourProgress]: 'Shahid taqaddumika',
    [OnboardingLanguageKey.Even]: 'Hatta',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'Litatahaqqaq min talaaqat kalimatuka',
    [OnboardingLanguageKey.With]: 'Ma’a',
    [OnboardingLanguageKey.Inline]: 'Mudammanah',
    [OnboardingLanguageKey.Quizzes]: 'Ikhtibaraat',
    [OnboardingLanguageKey.NeedABreak]: 'Hal tahtaaj ila istiraahah?',
    [OnboardingLanguageKey.Pause]: 'Awqif',
    [OnboardingLanguageKey.From]: 'Min',
    [OnboardingLanguageKey.Browser]: 'Mutsaffih',
    [OnboardingLanguageKey.Or]: 'Aw',
    [HomeLanguageKey.Language]: 'Lughah',
    [HomeLanguageKey.Easy]: 'Sahlan',
    [HomeLanguageKey.Words]: 'Kalimaat',
    [HomeLanguageKey.Games]: 'Al’aab',
    [HomeLanguageKey.Progress]: 'Taqaddumka',
    [HomeLanguageKey.Geniuses]: 'Nawaabigh',
    [RedeemLanguageKey.Key]: 'Miftaah',
    [RedeemLanguageKey.New]: 'Jadeedah',
    [RedeemLanguageKey.Anything]: 'Ay Shay',
  },
  [Language.Hebrew]: {
    [LanguageCapitalized.Hebrew]: 'ivrit',
    [SubscriptionLanguageKey.Subscription]: 'minuy',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'apne aap se anuvaad karega',
    [OnboardingLanguageKey.For]: 'liye',
    [OnboardingLanguageKey.While]: 'jab',
    [OnboardingLanguageKey.Crucial]: 'mehtavpoorn',
    [OnboardingLanguageKey.Successfully]: 'safalataapoorvak',
    [OnboardingLanguageKey.VisitAWebsite]: 'kisee website par jaate hain',
    [OnboardingLanguageKey.CertainWords]: 'kuchh shabdon',
    [OnboardingLanguageKey.Page]: 'Prasth',
    [OnboardingLanguageKey.Learn]: 'seekhne',
    [OnboardingLanguageKey.YouCanLearn]: 'aap seekh sakte hain',
    [OnboardingLanguageKey.Language]: 'bhasha',
    [OnboardingLanguageKey.Context]: 'sandarbh',
    [OnboardingLanguageKey.Comfortable]: 'sehaj',
    [OnboardingLanguageKey.ItsSimple]: 'aasaan hai',
    [OnboardingLanguageKey.And]: 'aur',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'vaise hee browse karen jaise aap',
    [OnboardingLanguageKey.ToBegin]: 'shuruaat karne ke liye',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'kisee bhi anuvaad par hover karen',
    [OnboardingLanguageKey.ToHearPronunciations]: 'uchchaaran sunane ke liye',
    [OnboardingLanguageKey.Later]: 'baad',
    [OnboardingLanguageKey.ViewYourProgress]: 'apanee pragati ko dekhen',
    [OnboardingLanguageKey.Even]: 'yahan tak ki',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'apane shabd pravaah kee jaanch karen',
    [OnboardingLanguageKey.With]: 'ke saath',
    [OnboardingLanguageKey.Inline]: 'inline',
    [OnboardingLanguageKey.Quizzes]: 'quiz',
    [OnboardingLanguageKey.NeedABreak]: 'Break lene ki jaroorat hai?',
    [OnboardingLanguageKey.Pause]: 'roken',
    [OnboardingLanguageKey.From]: 'se',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'yaa',
    [HomeLanguageKey.Language]: 'safa',
    [HomeLanguageKey.Easy]: 'kal',
    [HomeLanguageKey.Words]: 'milim',
    [HomeLanguageKey.Games]: 'mischakim',
    [HomeLanguageKey.Progress]: 'hitkadmut',
    [HomeLanguageKey.Geniuses]: "ge'onim",
    [RedeemLanguageKey.Key]: "mafte'ach",
    [RedeemLanguageKey.New]: 'chadasha',
    [RedeemLanguageKey.Anything]: 'kol davar',
  },
  [Language.Hindi]: {
    [LanguageCapitalized.Hindi]: 'hindi',
    [SubscriptionLanguageKey.Subscription]: 'graahak chandaa',
    [OnboardingLanguageKey.WillAutomaticallyTranslate]: 'apne aap se anuvaad karega',
    [OnboardingLanguageKey.For]: 'liye',
    [OnboardingLanguageKey.While]: 'jab',
    [OnboardingLanguageKey.Crucial]: 'mehtavpoorn',
    [OnboardingLanguageKey.Successfully]: 'safalataapoorvak',
    [OnboardingLanguageKey.VisitAWebsite]: 'kisee website par jaate hain',
    [OnboardingLanguageKey.CertainWords]: 'kuchh shabdon',
    [OnboardingLanguageKey.Page]: 'Prasth',
    [OnboardingLanguageKey.Learn]: 'seekhne',
    [OnboardingLanguageKey.YouCanLearn]: 'aap seekh sakte hain',
    [OnboardingLanguageKey.Language]: 'bhasha',
    [OnboardingLanguageKey.Context]: 'sandarbh',
    [OnboardingLanguageKey.Comfortable]: 'sehaj',
    [OnboardingLanguageKey.ItsSimple]: 'aasaan hai',
    [OnboardingLanguageKey.And]: 'aur',
    [OnboardingLanguageKey.BrowseAsYouNormallyDo]: 'vaise hee browse karen jaise aap',
    [OnboardingLanguageKey.ToBegin]: 'shuruaat karne ke liye',
    [OnboardingLanguageKey.HoverOverAnyTranslations]: 'kisee bhi anuvaad par hover karen',
    [OnboardingLanguageKey.ToHearPronunciations]: 'uchchaaran sunane ke liye',
    [OnboardingLanguageKey.Later]: 'baad',
    [OnboardingLanguageKey.ViewYourProgress]: 'apanee pragati ko dekhen',
    [OnboardingLanguageKey.Even]: 'yahan tak ki',
    [OnboardingLanguageKey.CheckYourWordFluency]: 'apane shabd pravaah kee jaanch karen',
    [OnboardingLanguageKey.With]: 'ke saath',
    [OnboardingLanguageKey.Inline]: 'inline',
    [OnboardingLanguageKey.Quizzes]: 'quiz',
    [OnboardingLanguageKey.NeedABreak]: 'Break lene ki jaroorat hai?',
    [OnboardingLanguageKey.Pause]: 'roken',
    [OnboardingLanguageKey.From]: 'se',
    [OnboardingLanguageKey.Browser]: 'browser',
    [OnboardingLanguageKey.Or]: 'yaa',
    [HomeLanguageKey.Language]: 'bhaashaa',
    [HomeLanguageKey.Easy]: 'aasaan',
    [HomeLanguageKey.Words]: 'shabd',
    [HomeLanguageKey.Games]: 'khel',
    [HomeLanguageKey.Progress]: 'pragati',
    [HomeLanguageKey.Geniuses]: 'pratibhashali',
    [RedeemLanguageKey.Key]: 'chabi',
    [RedeemLanguageKey.New]: 'naya',
    [RedeemLanguageKey.Anything]: 'kuch bhi',
  },
};
