import { Language } from '~/constants/languages';

import { useLanguageInfo, getLanguageIdFromLanguage } from '../useLanguageInfo';

import { dictionary, LanguageKey } from './dictionary';
import { pronunciations } from './pronunciation';

/**
 * useToucanTranslation is a more type-safe `useTranslation` and is based
 * on the users learning language and not their source language
 * @param language The language the user is leaning.
 */
export const useToucanTranslation = (language: Language, languageVariant?: string | null) => {
  const { languageInfo } = useLanguageInfo();
  const languageId = getLanguageIdFromLanguage(languageInfo[language], language, languageVariant);
  return {
    /**
     * tt is short of Toucan Translation its a good way to differentiate between
     * t ( a translation ) and tt ( a toucan translation )
     * @param key A key to access a translation
     */
    tt: (key: LanguageKey) => dictionary[languageId][key],
    resource: dictionary[languageId],
  };
};

export const getPronunciation = (language: Language, sourcePhrase: string) => {
  return pronunciations[language]?.[sourcePhrase];
};
